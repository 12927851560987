import {getRecord, patchRecord, postRecord, putRecord} from "@/services/api";

export function register(data, options = {}) {
    return postRecord("/prologistics/dashboard.json", {employee: data}, options)
}

export function login(data, options = {}) {
    options.toast = {skip: true}
    return postRecord("/prologistics/dashboard/sign_in.json", {employee: data}, options)
}

export function logout(filterQuery = {}, options = {}) {
    return getRecord("/prologistics/dashboard/sign_out.json", filterQuery, options)
}

export function sendResetPasswordEmail(email, options = {}) {
    options.toast = {body: 'password.send_instructions'}
    return postRecord("/prologistics/dashboard/password.json", {employee: {email: email}}, options)
}

export function sendConfirmationEmail(email, options = {}) {
    return postRecord('/prologistics/dashboard/confirmation.json', {employee: {email: email}}, options)
}

export function updatePassword(current_password, password, password_confirmation, options = {}) {
    return putRecord("/prologistics/dashboard/password.json", {
            employee: {
                current_password: current_password,
                password: password,
                password_confirmation: password_confirmation
            }
        },
        options
    )
}

export function updatePasswordByResetToken(token, password, password_confirmation, options = {}) {
    return putRecord("/prologistics/dashboard/password.json", {
            employee: {
                reset_password_token: token,
                password: password,
                password_confirmation: password_confirmation
            }
        },
        options
    )
}

export function getConfirmation(token, options = {}) {
    return getRecord("/prologistics/dashboard/confirmation.json", {confirmation_token: token}, options)
}

export function getEmployee(filterQuery = {}, options = {}) {
    return getRecord('/prologistics/dashboard/employees/current.json', filterQuery, options)
}

export function putEmployee(id, data, options = {}) {
    return putRecord('/prologistics/dashboard/employees/' + id + '.json', data, options)
}

export function patchEmployeeAcceptGTC(id, data, options = {}) {
    return patchRecord('/prologistics/dashboard/employees/' + id + '/accept_global_terms_and_conditions.json', data, options)
}

