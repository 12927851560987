import Vue from "vue";
import VueRouter from "vue-router";
import Onboarding from "@/layouts/onboarding";
import store from "@/store"
import Cookies from 'js-cookie'
import {getConfirmation, getEmployee} from "@/services/employees"
import Dashboard from "@/layouts/dashboard.vue";
import Legal from "@/layouts/legal.vue";

Vue.use(VueRouter);

const public_routes = [
    {
        path: "/public/transfers/carousel",
        name: "public_transfers_carousel",
        meta: {
            permissions: {
                public: true
            }
        },
        component: () => import("@/views/public/transfers_carousel"),
    },
];

const tracking_routes = [
    {
        path: "/tracking/:id",
        name: "tracking",
        meta: {
            permissions: {
                public: true
            }
        },
        component: () => import("@/views/tracking/tracking"),
    }
];

const dashboard_routes = [
    {
        path: "/dashboard/login",
        name: "login",
        meta: {
            permissions: {
                public: true,
                public_only: true
            }
        },
        component: () => import("@/views/employee/login"),
    },
    {
        path: "/dashboard/password/reset",
        name: "password_reset",
        meta: {
            permissions: {
                public: true,
                public_only: true
            }
        },
        component: () => import("@/views/employee/password_reset"),
    },
    {
        path: "/dashboard/legal",
        component: Legal,
        children: [
            {
                path: "/dashboard/terms",
                alias: "/dashboard/legal",
                name: "terms",
                meta: {
                    context: 'legal',
                    permissions: {
                        public: true
                    }
                },
                component: () => import("@/views/legal/terms"),
            },
            {
                path: "/dashboard/privacy",
                name: "privacy",
                meta: {
                    context: 'legal',
                    permissions: {
                        public: true
                    }
                },
                component: () => import("@/views/legal/privacy"),
            },
            {
                path: "/dashboard/imprint",
                name: "imprint",
                meta: {
                    context: 'legal',
                    permissions: {
                        public: true
                    }
                },
                component: () => import("@/views/legal/imprint"),
            },
            {
                path: "/dashboard/penalties",
                name: "penalties",
                meta: {
                    context: 'legal',
                    permissions: {
                        public: true
                    }
                },
                component: () => import("@/views/legal/penalties"),
            }
        ]
    },
    {
        path: "/dashboard",
        alias: "/",
        component: Dashboard,
        children: [
            {
                path: "/dashboard",
                name: "dashboard",
                component: () => {
                    let current_user = store.getters["user/current_user"] || null
                    return import("@/views/" + (current_user?.client?.role_name || 'rental') + "/dashboard")
                },
            },
            {
                path: "/dashboard/prepredict",
                name: "prepredict",
                meta: {
                    permissions: {
                        client: {
                            rental: true
                        }
                    }
                },
                component: () => import("@/views/rental/prepredict"),
            },
            {
                path: "/dashboard/transfers",
                name: "transfers",
                meta: {
                    permissions: {
                        client: {
                            rental: false
                        }
                    }
                },
                component: () => {
                    let current_user = store.getters["user/current_user"] || null
                    return import("@/views/" + current_user.client.role_name + "/transfers")
                },
            },
            {
                path: "/dashboard/drivers",
                name: "drivers",
                meta: {
                    permissions: {
                        client: {
                            rental: false,
                            driver: false
                        }
                    }
                },
                component: () => import("@/views/service/drivers"),
            },
            {
                path: "/dashboard/invoices",
                name: "invoices",
                meta: {},
                component: () => {
                    let current_user = store.getters["user/current_user"] || null
                    return import("@/views/" + current_user.client.role_name + "/invoices")
                }
            },
            {
                path: "/dashboard/clients",
                name: "clients",
                meta: {
                    permissions: {
                        admin: true
                    }
                },
                component: () => import("@/views/clients"),
            },
            {
                path: "/dashboard/price_lists",
                name: "price_lists",
                meta: {
                    permissions: {
                        admin: true
                    }
                },
                component: () => import("@/views/price_lists"),
            },
            {
                path: "/dashboard/managers",
                name: "managers",
                meta: {
                    permissions: {
                        admin: true
                    }
                },
                component: () => import("@/views/managers"),
            },
            {
                path: "/dashboard/dispatchers",
                name: "dispatchers",
                meta: {
                    permissions: {
                        manager: true,
                        client: {
                            driver: false
                        }
                    }
                },
                component: () => import("@/views/dispatchers"),
            }
        ]
    }
];

const onboarding_routes = [
    {
        path: "/registration",
        component: Onboarding, // TODO: can be moved to dashboard when component equals Application
        children: [
            {
                path: "/registration",
                name: "registration",
                meta: {
                    context: 'onboarding', // TODO: currently not used
                    permissions: {
                        public: true
                    }
                },
                component: () => import("@/views/employee/registration.vue"),
            },
        ]
    },
    {
        path: "/onboarding",
        component: Onboarding,
        children: [
            {
                path: "/onboarding",
                name: "onboarding_dashboard",
                meta: {
                    context: 'onboarding',
                    permissions: {
                        manager: true,
                        client: {
                            state_name: "pending"
                        }
                    }
                },
                component: () => import("@/views/onboarding/dashboard")
            },
            {
                path: "/onboarding/bank_account",
                name: "onboarding_bank_account",
                meta: {
                    context: 'onboarding',
                    permissions: {
                        manager: true,
                        client: {
                            state_name: "pending"
                        }
                    }
                },
                component: () => import("@/views/onboarding/bank_account")
            },
            {
                path: "/onboarding/tax_data",
                name: "onboarding_tax_data",
                meta: {
                    context: 'onboarding',
                    permissions: {
                        manager: true,
                        client: {
                            state_name: "pending",
                            bank_account: {
                                account_owner_name: (entry) => entry !== null,
                                bank_name: (entry) => entry !== null,
                                iban: (entry) => entry !== null,
                                bic: (entry) => entry !== null
                            }
                        }
                    }
                },
                component: () => import("@/views/onboarding/tax_data")
            },
            {
                path: "/onboarding/good_conduct_certificate",
                name: "onboarding_good_conduct_certificate",
                meta: {
                    context: 'onboarding',
                    permissions: {
                        manager: true,
                        client: {
                            state_name: "pending",
                            tax_number: (entry) => entry !== null,
                            business_license_images: (entry) => entry.length > 0
                        }
                    }
                },
                component: () => import("@/views/onboarding/good_conduct_certificate")
            },
            {
                path: "/onboarding/proof_of_insurance",
                name: "onboarding_proof_of_insurance",
                meta: {
                    context: 'onboarding',
                    permissions: {
                        manager: true,
                        client: {
                            state_name: "pending",
                            good_conduct_certificate_images: (entry) => entry.length > 0
                        }
                    }
                },
                component: () => import("@/views/onboarding/proof_of_insurance")
            },
            {
                path: "/onboarding/qundo_verification",
                name: "onboarding_qundo_verification",
                alias: "/onboarding/qundo",
                meta: {
                    context: 'onboarding',
                    permissions: {
                        manager: true,
                        client: (entry) => {
                            return entry.state_name === 'pending' && (entry.insurance_type === 0 || entry.proof_of_insurance_images.length > 0)
                        },
                        qundo_verification: (entry) => {
                            return entry === null || entry.state !== 1
                        }
                    }
                },
                component: () => import("@/views/onboarding/qundo_verification")
            },
            {
                path: "/onboarding/appointment",
                name: "onboarding_appointment",
                meta: {
                    context: 'onboarding',
                    permissions: {
                        manager: true,
                        client: {
                            state_name: "pending"
                        },
                        qundo_verification: {
                            state: 1
                        }
                    }
                },
                component: () => import("@/views/onboarding/appointment")
            }
        ]
    }
]

const employee_routes = [
    {
        path: "/employee/confirmation",
        redirect: "/dashboard"
        // explicit permission handling in router
    },
    {
        path: "/employee/password",
        alias: "/dashboard/password",
        name: "password",
        meta: {
            permissions: {
                public: true
            }
        },
        component: () => import("@/views/employee/password"),
    }
];

const user_flipcar_routes = [
    {
        path: "/user/flipcar/password",
        alias: "/user/password", // legacy support
        name: "user_flipcar_password",
        meta: {
            title: 'Flipcar',
            favicon: '/favicon_flipcar.png',
            permissions: {
                public: true
            }
        },
        component: () => import("@/views/user/flipcar/password")
    },
    {
        path: "/user/flipcar/delete_account_instructions",
        alias: "/user/delete_account_instructions", // legacy support
        name: "user_flipcar_delete_account_instructions",
        meta: {
            title: 'Flipcar',
            favicon: '/favicon_flipcar.png',
            permissions: {
                public: true
            }
        },
        component: () => import("@/views/user/flipcar/delete_account_instructions")
    },
    {
        path: "/user/flipcar/delete_account",
        alias: "/user/delete_account", // legacy support
        name: "user_flipcar_delete_account",
        meta: {
            title: 'Flipcar',
            favicon: '/favicon_flipcar.png',
            permissions: {
                public: true
            }
        },
        component: () => import("@/views/user/flipcar/delete_account")
    },
    {
        path: "/user/flipcar/terms",
        alias: "/user/terms", // legacy support
        name: "user_flipcar_terms",
        meta: {
            title: 'Flipcar',
            favicon: '/favicon_flipcar.png',
            permissions: {
                public: true
            }
        },
        component: () => {
            window.location.href = "https://flipcar.app/nutzungsbedingungen/"
        }
    },
    {
        path: "/user/flipcar/privacy",
        alias: "/user/privacy", // legacy support
        name: "user_flipcar_privacy",
        meta: {
            title: 'Flipcar',
            favicon: '/favicon_flipcar.png',
            permissions: {
                public: true
            }
        },
        component: () => {
            window.location.href = "https://flipcar.app/datenschutz/"
        }
    },
    {
        path: "/user/flipcar/imprint",
        alias: "/user/imprint", // legacy support
        name: "user_flipcar_imprint",
        meta: {
            title: 'Flipcar',
            favicon: '/favicon_flipcar.png',
            permissions: {
                public: true
            }
        },
        component: () => {
            window.location.href = "https://flipcar.app/impressum/"
        }
    },
    {
        path: "/user/flipcar/faq",
        alias: "/user/faq", // legacy support
        name: "user_flipcar_faq",
        meta: {
            title: 'Flipcar',
            favicon: '/favicon_flipcar.png',
            permissions: {
                public: true
            }
        },
        component: () => {
            window.location.href = "https://flipcar.app/faq/"
        }
    }
];

const user_ugoki_routes = [
    {
        path: "/user/ugoki/password",
        name: "user_ugoki_password",
        meta: {
            title: 'Ugoki',
            favicon: '/favicon_ugoki.png',
            permissions: {
                public: true
            }
        },
        component: () => import("@/views/user/ugoki/password")
    },
    {
        path: "/user/ugoki/delete_account_instructions",
        name: "user_ugoki_delete_account_instructions",
        meta: {
            title: 'Ugoki',
            favicon: '/favicon_ugoki.png',
            permissions: {
                public: true
            }
        },
        component: () => import("@/views/user/ugoki/delete_account_instructions")
    },
    {
        path: "/user/ugoki/delete_account",
        name: "user_ugoki_delete_account",
        meta: {
            title: 'Ugoki',
            favicon: '/favicon_ugoki.png',
            permissions: {
                public: true
            }
        },
        component: () => import("@/views/user/ugoki/delete_account")
    },
    {
        path: "/user/ugoki/terms",
        name: "user_ugoki_terms",
        meta: {
            title: 'Ugoki',
            favicon: '/favicon_ugoki.png',
            permissions: {
                public: true
            }
        },
        component: () => {
            window.location.href = "https://ugoki.com/nutzungsbedingungen/"
        }
    },
    {
        path: "/user/ugoki/privacy",
        name: "user_ugoki_privacy",
        meta: {
            title: 'Ugoki',
            favicon: '/favicon_ugoki.png',
            permissions: {
                public: true
            }
        },
        component: () => {
            window.location.href = "https://ugoki.com/datenschutz/"
        }
    },
    {
        path: "/user/ugoki/imprint",
        name: "user_ugoki_imprint",
        meta: {
            title: 'Ugoki',
            favicon: '/favicon_ugoki.png',
            permissions: {
                public: true
            }
        },
        component: () => {
            window.location.href = "https://ugoki.com/impressum/"
        }
    },
    {
        path: "/user/ugoki/faq",
        name: "user_ugoki_faq",
        meta: {
            title: 'Ugoki',
            favicon: '/favicon_ugoki.png',
            permissions: {
                public: true
            }
        },
        component: () => {
            window.location.href = "https://ugoki.com/faq/"
        }
    }
];

const driver_routes = [
    {
        path: "/driver/password",
        name: "driver_password",
        meta: {
            permissions: {
                public: true
            }
        },
        component: () => import("@/views/driver/password"),
    },
    {
        path: "/driver/terms",
        alias: "/prologistics/api/v1/legal/terms",
        name: "driver_terms",
        meta: {
            permissions: {
                public: true
            }
        },
        component: () => {
            window.location.href = "https://prologistics.io/terms-app/"
        }
    },
    {
        path: "/driver/privacy",
        alias: "/prologistics/api/v1/legal/privacy",
        name: "driver_privacy",
        meta: {
            permissions: {
                public: true
            }
        },
        component: () => {
            window.location.href = "https://prologistics.io/privacy-app/"
        }
    },
    {
        path: "/driver/imprint",
        alias: "/prologistics/api/v1/legal/imprint",
        name: "driver_imprint",
        meta: {
            permissions: {
                public: true
            }
        },
        component: () => {
            window.location.href = "https://prologistics.io/impressum/"
        }
    },
    {
        path: "/driver/faq",
        alias: "/prologistics/api/v1/legal/faq",
        name: "driver_faq",
        meta: {
            permissions: {
                public: true
            }
        },
        component: () => {
            window.location.href = "https://faq.prologistics.io/"
        }
    }
];

const app_deeplinks = [
    {
        path: "/app/flipcar",
        alias: "/flipcar/app", // legacy support
        name: "app_flipcar",
        meta: {
            permissions: {
                public: true
            }
        },
        component: () => {
            if (Vue.prototype.$hasUserAgentIos()) {
                window.location.href = "flipcarapp://flipcar.app/auth" + window.location.search
            } else if (Vue.prototype.$hasUserAgentAndroid()) {
                window.location.href = "flipcarapp://flipcar.app" + window.location.search
            }
            setTimeout(() => {
                if (Vue.prototype.$hasUserAgentIos() || Vue.prototype.$hasPlatformMac()) {
                    window.location.href = "https://apps.apple.com/de/app/id/1451050779"
                } else {
                    window.location.href = "https://play.google.com/store/apps/details?id=com.drivo.flipcar"
                }
            }, 2500);
        }
    },
    {
        path: "/app/prologistics",
        alias: "/prologistics/app", // legacy support
        name: "app_prologistics",
        meta: {
            permissions: {
                public: true
            }
        },
        component: () => {
            if (Vue.prototype.$hasUserAgentIos()) {
                window.location.href = "prologisticsapp://prologistics.flipcar.app/auth" + window.location.search
            } else if (Vue.prototype.$hasUserAgentAndroid()) {
                window.location.href = "prologisticsapp://prologistics.flipcar.app" + window.location.search
            }
            setTimeout(() => {
                if (Vue.prototype.$hasUserAgentIos() || Vue.prototype.$hasPlatformMac()) {
                    window.location.href = "https://apps.apple.com/de/app/id/1596311833"
                } else {
                    window.location.href = "https://play.google.com/store/apps/details?id=app.flipcar.prologistics"
                }
            }, 2500);
        }
    },
    {
        path: "/app/ugoki",
        alias: "/ugoki/app", // legacy support
        name: "app_ugoki",
        meta: {
            permissions: {
                public: true
            }
        },
        component: () => {
            if (Vue.prototype.$hasUserAgentIos()) {
                window.location.href = "ugokiapp://ugoki.app/auth" + window.location.search
            } else if (Vue.prototype.$hasUserAgentAndroid()) {
                window.location.href = "ugokiapp://ugoki.app" + window.location.search
            }
            setTimeout(() => {
                if (Vue.prototype.$hasUserAgentIos() || Vue.prototype.$hasPlatformMac()) {
                    window.location.href = "https://apps.apple.com/de/app/id/6503616401"
                } else {
                    window.location.href = "https://play.google.com/store/apps/details?id=app.ugoki.ugoki"
                }
            }, 2500);
        }
    },
    {
        path: "/app/flipcar/qundo",
        alias: "/qundo", // legacy support
        meta: {
            permissions: {
                public: true
            }
        },
        component: () => {
            window.location.href = window.location.origin + "/app/flipcar?qundo=1" // couldnt bring redirect with query to work
        }
    },
    {
        path: "/app/prologistics/qundo",
        meta: {
            permissions: {
                public: true
            }
        },
        component: () => {
            window.location.href = window.location.origin + "/app/prologistics?qundo=1" // couldnt bring redirect with query to work
        }
    },
    {
        path: "/app/ugoki/qundo",
        meta: {
            permissions: {
                public: true
            }
        },
        component: () => {
            window.location.href = window.location.origin + "/app/ugoki?qundo=1" // couldnt bring redirect with query to work
        }
    }
]

const app_routes = [
    {
        path: "/app/flipcar/payment/authorization/success",
        alias: "/flipcar/app/payment/authorization/success", // legacy support
        meta: {
            title: 'Flipcar',
            favicon: '/favicon_flipcar.png',
            permissions: {
                public: true
            }
        },
        component: () => {
            // removing query for now
            if (window.location.search !== '') {
                window.history.replaceState({}, document.title, window.location.pathname);
                return window.location.href = window.location.pathname;
            }
            return import("@/views/app/flipcar/payment/authorization/success")
        }
    },
    {
        path: "/app/flipcar/payment/authorization/failure",
        alias: "/flipcar/app/payment/authorization/failure", // legacy support
        meta: {
            title: 'Flipcar',
            favicon: '/favicon_flipcar.png',
            permissions: {
                public: true
            }
        },
        component: () => {
            // removing query for now
            if (window.location.search !== '') {
                window.history.replaceState({}, document.title, window.location.pathname);
                return window.location.href = window.location.pathname;
            }
            return import("@/views/app/flipcar/payment/authorization/failure")
        }
    },
    {
        path: "/app/flipcar/payment/checkout/success",
        alias: "/flipcar/app/payment/checkout/success", // legacy support
        meta: {
            title: 'Flipcar',
            favicon: '/favicon_flipcar.png',
            permissions: {
                public: true
            }
        },
        component: () => {
            // removing query for now
            if (window.location.search !== '') {
                window.history.replaceState({}, document.title, window.location.pathname);
                return window.location.href = window.location.pathname;
            }
            return import("@/views/app/flipcar/payment/checkout/success")
        }
    },
    {
        path: "/app/flipcar/payment/checkout/failure",
        alias: "/flipcar/app/payment/checkout/failure", // legacy support
        meta: {
            title: 'Flipcar',
            favicon: '/favicon_flipcar.png',
            permissions: {
                public: true
            }
        },
        component: () => {
            // removing query for now
            if (window.location.search !== '') {
                window.history.replaceState({}, document.title, window.location.pathname);
                return window.location.href = window.location.pathname;
            }
            return import("@/views/app/flipcar/payment/checkout/failure")
        }
    },
    {
        path: "/app/ugoki/payment/authorization/success",
        meta: {
            title: 'Ugoki',
            favicon: '/favicon_ugoki.png',
            permissions: {
                public: true
            }
        },
        component: () => {
            // removing query for now
            if (window.location.search !== '') {
                window.history.replaceState({}, document.title, window.location.pathname);
                return window.location.href = window.location.pathname;
            }
            return import("@/views/app/ugoki/payment/authorization/success")
        }
    },
    {
        path: "/app/ugoki/payment/authorization/failure",
        meta: {
            title: 'Ugoki',
            favicon: '/favicon_ugoki.png',
            permissions: {
                public: true
            }
        },
        component: () => {
            // removing query for now
            if (window.location.search !== '') {
                window.history.replaceState({}, document.title, window.location.pathname);
                return window.location.href = window.location.pathname;
            }
            return import("@/views/app/ugoki/payment/authorization/failure")
        }
    },
    {
        path: "/app/ugoki/payment/checkout/success",
        meta: {
            title: 'Ugoki',
            favicon: '/favicon_ugoki.png',
            permissions: {
                public: true
            }
        },
        component: () => {
            // removing query for now
            if (window.location.search !== '') {
                window.history.replaceState({}, document.title, window.location.pathname);
                return window.location.href = window.location.pathname;
            }
            return import("@/views/app/ugoki/payment/checkout/success")
        }
    },
    {
        path: "/app/ugoki/payment/checkout/failure",
        meta: {
            title: 'Ugoki',
            favicon: '/favicon_ugoki.png',
            permissions: {
                public: true
            }
        },
        component: () => {
            // removing query for now
            if (window.location.search !== '') {
                window.history.replaceState({}, document.title, window.location.pathname);
                return window.location.href = window.location.pathname;
            }
            return import("@/views/app/ugoki/payment/checkout/failure")
        }
    }
];

const default_routes = [
    {
        path: "/dashboard/404",
        name: "404",
        // explicit permission handling in router
        component: () => import("@/views/404"),
    },
    {
        path: "/dashboard/500",
        name: "500",
        // explicit permission handling in router
        component: () => import("@/views/500")
    },
    {
        path: "*",
        redirect: "/dashboard/404"
    }
];

const routes = public_routes
    .concat(tracking_routes)
    .concat(dashboard_routes)
    .concat(onboarding_routes)
    .concat(employee_routes)
    .concat(user_flipcar_routes)
    .concat(user_ugoki_routes)
    .concat(driver_routes)
    .concat(app_deeplinks)
    .concat(app_routes)
    .concat(default_routes);

// append optional .html suffix to path and alias
function addHtmlFormatSuffixSupport(routes) {
    routes.forEach(route => {
        if (route.path !== '*') {
            route.path = `${route.path}:format(\\..*)?`;
        }
        if (route.alias) {
            route.alias = `${route.alias}:format(\\..*)?`;
        }
        if (route.children) {
            addHtmlFormatSuffixSupport(route.children);
        }
    });
}
addHtmlFormatSuffixSupport(routes);

const router = new VueRouter({
    mode: "history",
    base: '/', // process.env.BASE_URL
    routes
});

router.beforeEach(async (to, from, next) => {
    // set document title from route meta
    if (to.meta?.title !== undefined) {
        document.title = to.meta?.title
    }

    // set document favicon from route meta
    if (to.meta?.favicon !== undefined) {
        const favicon = document.getElementById('favicon')
        if (favicon !== undefined) {
            favicon.href = to.meta?.favicon
        }
    }

    //NProgress.start()

    // show 500 page
    if (to.name === "500") {
        next()
        return false
    }

    // show 404 page
    if (to.name === "404") {
        next()
        return false
    }

    // handle confirmation
    if (to.query.confirmation_token) {
        await getConfirmation(to.query.confirmation_token).then(response => {
            store.dispatch("user/setUser", response)
        }).catch(error => {
            store.dispatch("user/resetUser")
        })
        next({name: "dashboard"})
        return false
    }

    let current_user = store.getters["user/current_user"] || null

    // download user if required
    if (current_user == null && Cookies.get("employee_id")) {
        await getEmployee().then(response => {
            store.dispatch("user/setUser", response)
            current_user = response
        }).catch(error => {
            if (error.response?.status === 401) {
                Cookies.remove('employee_id') // TODO: safari requires explicit cookie removing (due to some kind of caching?)
            }
            store.dispatch("user/resetUser")
        })
    }

    // send to login if required
    if (current_user == null) {
        Vue.prototype.$wsDisconnect()
        if (to.meta?.permissions?.public) {
            next()
            return false
        }
        // save requested path to cookies to allow redirect after login
        Cookies.set("redirect", to.fullPath)
        next({name: "login"})
        return false
    }

    // send to password if required
    if (current_user.has_no_password && to.name !== "password") {
        next({name: "password"})
        return false
    }

    // send to onboarding if client is pending, still allow password page to be rendered
    if (current_user.client.state_name === "pending" && to.name !== "password" && to.meta?.context !== "onboarding" && !to.meta?.permissions?.public === true) {
        next({name: "onboarding_dashboard"})
        return false
    }

    // send to dashboard if permissions missing
    if (!Vue.prototype.$checkPermissions(to.meta?.permissions, current_user)) {
        next({name: "dashboard"})
        return false
    }

    // default handling
    Vue.prototype.$wsConnect()
    next()
})

export default router;


